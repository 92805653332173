export enum PaymentScheduleType {
  Regular = "regular",
  Fixed = "fixed"
}

export enum PaymentScheduleFrequency {
  Monthly = "monthly" // currently can only be monthly
}

export enum PaymentScheduleItemStatus {
  Completed = "completed",
  NextPayment = "nextPayment",
  Upcoming = "upcoming"
}

export interface PaymentScheduleItem {
  _id?: string;
  date: Date;
  amount: number;
  status?: PaymentScheduleItemStatus;
}

export interface FixedPaymentScheduleFormDataItem<A> {
  date: Date;
  amount: A;
}

export interface PaymentSchedule {
  type: PaymentScheduleType;
  amount?: number; // only used if regular
  frequency?: PaymentScheduleFrequency; // only used if regular
  firstPaymentDate?: Date; // only used if regular. must be 1st of month.
  finalPaymentDate?: Date; // only used if regular. must be 1st of month.
  schedule?: PaymentScheduleItem[]; // only used if fixed
}

export interface SubscriptionPlan {
  _id: string;
  name: string;
  description: string;
  initialPayment: number;
  paymentSchedule: PaymentSchedule;
  stripeProductId: string;
  disableAfterFirstPaymentDate?: boolean;
  allowUserCancellations?: boolean;
  userCancellationsMinNoticePeriod?: number;
  currentUserSubscriptions?: number;
  enabled: boolean;
  created: Date;
  updated: Date;
  client: string;
  save: () => Promise<SubscriptionPlan>;
}

export interface SubscriptionPlanFormData {
  name?: string;
  initialPayment?: number;
  paymentScheduleType?: PaymentScheduleType;
  regularPaymentAmount?: number;
  regularPaymentFirstPaymentDate?: Date;
  regularPaymentFinalPaymentDate?: Date;
  fixedPaymentSchedule?: FixedPaymentScheduleFormDataItem<string>[];
  disableAfterFirstPaymentDate?: boolean;
  allowUserCancellations?: boolean;
  userCancellationsMinNoticePeriod?: string;
  enabled?: boolean;
}

export interface SubscriptionPlanFormDataToSubmit {
  name?: string;
  description?: string;
  initialPayment?: number;
  paymentScheduleType?: PaymentScheduleType;
  regularPaymentAmount?: number;
  regularPaymentFirstPaymentDate?: Date;
  regularPaymentFinalPaymentDate?: Date;
  fixedPaymentSchedule?: FixedPaymentScheduleFormDataItem<number>[];
  disableAfterFirstPaymentDate?: boolean;
  allowUserCancellations?: boolean;
  userCancellationsMinNoticePeriod?: number;
  enabled?: boolean;
}
